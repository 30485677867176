import "./contact.css";
import {Fragment} from "react";

export const Contact = () => {
    return (
        <Fragment>
            <div className={"row justify-content-center mt-5"}>
                <div className={"col-10 text-center"}>
                    <a href={"https://www.linkedin.com/in/matt-roberts-the-coolest-dabbler-youve-ever-friggin-seen"}>LinkedIn</a>
                </div>
            </div>
            <div className={"row justify-content-center mt-5"}>
                <div className={"col-10 text-center"}>
                    <a href={"https://github.com/cheezballs"}>Github</a>
                </div>
            </div>
        </Fragment>
    )
}
