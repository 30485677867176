import "./header.css";
import {NavLink} from "react-router-dom";

export const Header = () => {

    return (
        <nav className="navbar navbar-expand-sm mb-5">
            <div className="container">
                <NavLink to={"/"} className={"navbar-brand"}><h2>Matt Roberts</h2></NavLink>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        style={{backgroundColor: "#199d76"}}
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse ms-5" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-sm-0">
                        <li className="nav-item pe-4">
                            <NavLink to={"/resume"} className={"nav-link active"}>Resume & CV</NavLink>
                        </li>
                        <li className="nav-item pe-4">
                            <NavLink to={"/projects"} className={"nav-link active"}>Projects</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={"/contact"} className={"nav-link active"}>Contact</NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}