import "./resume.css";

export const Resume = () => {
    return (
        <div className={"container"}>
            <div className={"row justify-content-center"}>
                <div className={"col-sm-12 col-md-9 mb-5 pb-5"}>
                    <h4>Expertise</h4>
                    <ul>
                        <li>RESTful Web Services</li>
                        <li>Responsive Web Applications</li>
                        <li>Database Management</li>
                        <li>Amazon Web Services</li>
                        <li>Automation</li>
                        <li>Web Security / OWASP Best Practices</li>
                    </ul>
                    <hr className={"m-5"}/>
                    <h4>Programming & Scripting Languages</h4>
                    <ul>
                        <li>Java</li>
                        <li>C#</li>
                        <li>JavaScript</li>
                        <li>SQL</li>
                        <li>Bash</li>
                        <li>C++</li>
                    </ul>
                    <hr className={"m-5"}/>
                    <h4>Frameworks & Libraries</h4>
                    <ul>
                        <li>Docker</li>
                        <li>Spring/Boot</li>
                        <li>JPA/Hibernate</li>
                        <li>GraphQL</li>
                        <li>ReactJS</li>
                        <li>Bootstrap</li>
                        <li>Bulma</li>
                        <li>Angular 2+</li>
                        <li>Entity Framework</li>
                        <li>Unity 3D</li>
                    </ul>
                    <hr className={"m-5"}/>
                    <h4>Professional History</h4>
                    <div className={"ms-3 mt-4"}>
                        <div className={"fw-bold mb-2"}>National Association Of Insurance Commissioners</div>
                        <div className={"ms-4"}>Senior Software Engineer : <span className={"work-details"}>Sept 2011 - Current</span>
                        </div>
                    </div>
                    <div className={"ms-3 mt-4"}>
                        <div className={"fw-bold mb-2"}>Nullhammer Solutions</div>
                        <div className={"ms-4"}>Overlord / Consultant : <span className={"work-details"}>Sept 2011 - June 2013</span>
                        </div>
                    </div>
                    <div className={"ms-3 mt-4"}>
                        <div className={"fw-bold mb-2"}>Boilermakers National Funds</div>
                        <div className={"ms-4"}>Web Developer : <span
                            className={"work-details"}>Aug 2006 - Sept 2011</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
