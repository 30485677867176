import "./footer.css";

export const Footer = () => {
    return (
        <div className={"container-fluid position-fixed fixed-bottom"}>
            <div className={"row"}>
                <div className={"col text-center"}>
                    &copy; {new Date().getFullYear()} Matt Roberts
                </div>
            </div>
        </div>
    )
}