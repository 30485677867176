import './App.css';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap/dist/js/bootstrap.bundle.min";
import {Header} from "./components/common/layout/Header";
import {Footer} from "./components/common/layout/Footer";
import {Home} from "./components/home/Home";
import {Resume} from "./components/resume/Resume";
import {Contact} from "./components/contact/Contact";
import {MattrisHome} from "./components/projects/mattris/MattrisHome";
import {Projects} from "./components/projects/Projects";

export const App = () => {
    return (
        <Router>
            <div className="App">
                <div className={"container"}>
                    <Header/>

                    <Routes>
                        <Route path="/" element={<Home/>}>
                        </Route>
                        <Route path="/resume" element={<Resume/>}>
                        </Route>
                        <Route path="/projects" element={<Projects/>}>
                        </Route>
                        <Route path="/contact" element={<Contact/>}>
                        </Route>
                        <Route path="/mattris" element={<MattrisHome/>}>
                        </Route>
                    </Routes>

                    <Footer/>
                </div>
            </div>
        </Router>
    )
}
