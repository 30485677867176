import React, {Fragment} from 'react';
import "./projects.css";
import {NavLink} from "react-router-dom";

export const Projects = () => {
    return (
        <Fragment>
            <div className={"row mt-2"}>
                <div className={"col-sm-4 col-md-4 col-lg-3"} style={{textAlign: "center"}}>
                    <NavLink to={"/mattris"}>
                        <img src={"/mattris.png"} alt={"Mattris Screenshot"}
                             style={{
                                 borderColor: "#20c997",
                                 backgroundColor: "#20c997",
                                 padding: ".1rem",
                                 marginBottom: "25px",
                                 width: "100%",
                                 maxWidth: "300px"
                             }}/>
                    </NavLink>
                </div>
                <div className={"col-sm-8 col-md-8 col-lg-8 ps-4"}>
                    <div>
                        <h3 className={"pb-2"}>Mattris</h3>
                        A Javascript implementation of Tetris using PhaserJS.<br/><br/>
                        It uses the arrow keys and space bar for inputs. I put very little effort into recreating
                        the semi-complex scoring logic of the original. So, yea... <br/><br/>
                        Check out the code on <a href={"https://github.com/cheezballs/mattrisjs"}>GitHub</a>.
                        Try it <NavLink to={"/mattris"}>here</NavLink>.
                    </div>
                </div>
            </div>
            <div className={"row mt-5"}>
                <div className={"col-sm-4 col-md-4 col-lg-3"} style={{textAlign: "center"}}>
                    <NavLink to={"https://waywardcookies.com"} target={"_blank"}>
                        <img src={"/wayward-logo.png"} alt={"Wayward Cookies"}
                             style={{
                                 borderColor: "#20c997",
                                 backgroundColor: "#20c997",
                                 padding: ".1rem",
                                 marginBottom: "25px",
                                 width: "100%",
                                 maxWidth: "300px"
                             }}/>
                    </NavLink>
                </div>
                <div className={"col-sm-8 col-md-8 col-lg-8 ps-4"}>
                    <div>
                        <h3 className={"pb-2"}>Wayward Cookies</h3>
                        Order custom decorated sugar cookies and macarons like you've never done before!
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
