import "./home.css";

export const Home = () => {
    return (
        <div className={"row"}>
            <div className={"col-sm-9 mb-5"}>
                <p>Hey. Its me.</p>
                <p>This is my site. My page. What do people call it now days?</p>
                <p>I'm a software engineer living in Blue Springs, MO.</p>
                <p>I like to make stuff. Usually software. Sometimes paintings. Occasionally wood.</p>
                <p>In my spare time I enjoy hanging out in my closet and listening to avant-garde atmospheric post-black
                    metal and Pat Benatar.</p>
            </div>
            <div className={"col-sm-3"}>
                <img src={"/me.jpg"} alt={"Me"} className={"img-thumbnail"}
                     style={{borderColor: "#20c997", backgroundColor: "#20c997", padding: ".1rem"}}/>
            </div>
        </div>
    )
}
